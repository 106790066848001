@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.page {
  width: 100%;
  flex: 1;
  position: relative;
  color: theme-var($--theme-page-text-color);
  background: #f6f8fb;

  .page-back-nav-header.sub-header {
    margin-top: $space * 2;
  }
  .page-back-nav-header {
    position: relative;
    display: flex;
    justify-content: center;

    &__back {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }

    &__title {
      margin: 0 auto;
      position: relative;
      width: 100%;
      max-width: calculateRem(200px);

      @media (min-width: 360px) {
        max-width: calculateRem(250px);
      }

      @media (min-width: 568px) {
        max-width: 75%;
      }

      h3 {
        margin: 0 auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
