@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.share-drawer {
  &__heading {
    color: map-get($theme-map-default, $--theme-selector-button-color--light);
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    .button {
      margin: auto $space;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: $space 0;
    justify-content: space-between;
  }

  &__text {
    flex: 1;
    text-align: left;
  }

  &__btn {
    padding: ($space * 1.5) $space * 2;

    &:hover,
    &:focus {
      background: theme-var($--theme-share-drawer-button-bg--active);
    }

    .loading--spinner {
      margin: 0;
    }

    &.button--disabled {
      opacity: 1;
    }
  }
}
