@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.sort-by-selector {
  &__heading {
    color: theme-var($--theme-selector-button-color--light);
  }

  &__radio {
    border-bottom: 1px solid theme-var($--theme-drawer-contents-border-color);
    padding: ($space * 2);
    padding-right: ($space * 3);

    &:last-child {
      border: none;
    }
  }

  &__slider {
    padding: ($space * 2) ($space * 2) ($space * 5) ($space * 2);
    border-top: 1px solid #dce7ef;
  }
}
