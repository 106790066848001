@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.comps-list {
  position: relative;
  z-index: 1;

  &__item {
    margin-bottom: $space;
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      overflow: hidden;
      $color-opacity: 0.84;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &.bg-color--a:after {
        background-color: rgba(#fd7e14, $color-opacity);
      }

      &.bg-color--b:after {
        background-color: rgba(#20c997, $color-opacity);
      }

      &.bg-color--c:after {
        background-color: rgba(#ea78b7, $color-opacity);
      }

      &.bg-color--d:after {
        background-color: rgba(#f5af25, $color-opacity);
      }

      .bg__image {
        display: block;
        opacity: 0;
        transition: none;

        width: 100%;
        height: 100%;
        object-fit: cover;

        &--is-loaded {
          opacity: 1;
          transition: opacity 300ms linear;
        }
      }
    }

    &__body {
      position: relative; // neccessary to have text over the overlay
      z-index: 1;
    }

    &__title {
      padding: $space ($space * 2);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      max-width: calculateRem(250px);

      @media (min-width: 360px) {
        max-width: calculateRem(295px);
      }

      @media (min-width: 568px) {
        max-width: calculateRem(380px);
      }

      &--skeleton {
        @include skeleton(100px, 18px);
      }
    }

    .stats {
      display: flex;
      flex-direction: column;

      &__ctn {
        &--local {
          flex: 0 0 auto;
          flex-direction: column;
          display: flex;

          .stat__metric-ctn {
            margin: ($space * 0.5) 0;
          }

          .stat__logo-ctn {
            height: ($space * 3);
          }
        }

        &--comscore {
          &:last-child {
            margin-bottom: ($space);
          }

          .stat__logo-ctn {
            margin-bottom: ($space);
          }

          .stat__share {
            display: flex;
            flex: 1;
            justify-content: flex-end;
          }
        }
      }

      .stat {
        $base-stat: '.stat';

        display: flex;
        flex-direction: column;
        width: 100%;

        &__date {
          line-height: 1.1428;
          display: block;
        }

        &__metric-ctn {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0 ($space * 2);
        }

        &__comps-title {
          display: flex;

          .stat__logo {
            padding-left: $space;
          }
        }

        &__logo {
          width: calculateRem(20px);
          height: calculateRem(20px);

          &--skeleton {
            background-color: $color-white;
            border-radius: 50%;
            opacity: 0.1;
          }
        }

        &__logo-ctn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: rgba(255, 255, 255, 0.3);
          padding: 0 ($space * 2);
        }

        &__no-market-data {
          text-align: center;
          margin: ($space * 4) auto ($space * 4) auto;
        }

        &__label {
          line-height: 1.1428; // NOTE: particular to this card only.
          flex: 1;
          max-width: 38%;
        }

        &--skeleton {
          #{$base-stat}__label,
          #{$base-stat}__share {
            @include skeleton();
            margin: 0;
          }
          #{$base-stat}__value {
            @include skeleton(55px);
          }
        }
      }
    }
  }
}
