@import '../../Styles/resources/variables';
@import '../../Styles/resources/theme_variables';
@import '../../Styles/resources/typography_variables';
@import '../../Styles/resources/mixins';

.auth-footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: auto;

    &__footer-logo {
      margin-top: calculateRem(22px);
      margin-bottom: calculateRem(22px);
      width: calculateRem(175px);
    }
  }
