@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.back-to-top {
  position: fixed;
  bottom: $space * 2;
  right: $space * 2;
  opacity: 0;
  visibility: hidden;
  z-index: map-get($z-index, back-to-top);

  &__btn {
    padding: 0;

    .icon {
      font-size: calculateRem(66px);
      color: theme-var($--theme-backtotop-bg-color);
    }
  }
}
