@import '../../Styles/resources/variables';
@import '../../Styles/resources/theme_variables';
@import '../../Styles/resources/typography_variables';
@import '../../Styles/resources/mixins';

.user-settings {
  &__header {
    padding-left: $space;
    padding-right: $space;
  }

  &__title {
    text-align: center;
  }

  &-overlay {
    // As default overlay is under sidebar.
    z-index: map-get($z-index, app-sidebar) + 5;
    background-color: $color-white;
  }

  &-main {
    height: 100%;

    &__page {
      box-shadow: 0 0 0 4px #dce7ef;
      background-color: white;
    }

    .loading--spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .spinner__path {
        stroke: map-get($theme-map-films, $--theme-spinner-bg-color);
      }
    }

    &--loading {
      .section-container,
      .user-settings__header {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &-time-format {
    &__section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #dce7ef;
    }

    &__title {
      color: #045c88;

      &--disabled {
        opacity: 0.4;
      }
    }
  }

  &-toggle {
    position: relative;

    &--active {
      &:after {
        content: '';
        position: absolute;
        top: 5px;
        right: 10px;
        width: 7px;
        height: 7px;
        border-radius: 3px;
        background-color: #f15e5e;
      }
    }
  }

  &-list {
    .section-button:last-child {
      border-bottom: 1px solid #dce7ef;
    }
  }
}
