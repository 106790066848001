@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.theatre-list {
  width: 100%;
  flex: 1;

  &-ctn {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .lazy-load {
      padding: $space ($space * 2);

      opacity: 0;
      transition: none;
      pointer-events: none;

      &--is-visible {
        opacity: 1;
        transition: opacity 300ms linear;
      }
    }

    .list-spinner {
      display: flex;
      flex: 1;
      align-items: center;
    }
  }

  &__empty-ctn {
    color: #2a303e;
    display: flex;
    flex: 1;
    align-items: center;
  }

  &__item {
    position: relative;
    padding: ($space * 2);
    color: theme-var($--theme-theatre-list-item-color);
    background: theme-var($--theme-theatre-list-item-bg);
    margin-right: ($space * 2);
    margin-left: ($space * 2);
    box-sizing: border-box;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: $space;
    }

    .item-header {
      display: flex;
      margin-bottom: $space / 2;

      &__label {
        padding-left: $space;
        padding-right: $space * 2;
      }

      &__logo {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-left: $space;
      }
    }

    .item-header-subheader {
      position: relative;
      padding-left: $space;
      padding-right: $space * 2;
      margin-left: $space * 3;
      top: -$space;
      color: theme-var($--theme-theatre-list-item-color);
    }

    .item-content {
      display: flex;
      flex-direction: column;
    }

    .item-body {
      display: flex;
      padding-right: ($space * 2);
      padding-left: $space * 4;

      &__metric {
        flex: 1;
        padding-right: ($space * 3);

        &--inline {
          display: flex;
          align-items: center;

          .metric__label {
            padding-right: $space * 1.5;
          }

          .metric__percentage {
            margin-left: $space * 1.5;
          }
        }
      }
    }

    .item-toggle-aside {
      position: absolute;
      top: $space;
      right: $space;
      cursor: pointer;

      .arrow {
        width: calculateRem(10px);
        height: calculateRem(10px);

        &__path {
          fill: theme-var($--theme-theatre-list-arrow-color);
        }
      }
    }

    .item-aside {
      margin-left: -($space * 2);
      margin-right: -($space * 2);
      overflow: hidden;
    }

    .item-table-container {
      margin-top: ($space * 2);
      min-height: calculateRem(250px);
      max-height: calculateRem(345px);
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;

      &__scroll {
        @include hide-scrollbar();
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-x: hidden;
      }

      &__empty {
        display: flex;
        align-items: center;
      }

      .table__th,
      .table__td {
        text-align: center;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
