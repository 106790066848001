@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.header-group {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: $space;
  align-items: center;
  align-content: center;

  &__left {
    grid-column: 1;
    justify-self: start;
  }

  &__center {
    grid-column: 2;
    justify-self: center;
  }

  &__right {
    grid-column: 3;
    justify-self: end;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
