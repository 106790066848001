@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

$checkbox-size: 16px;
$checkbox-color: theme-var($--theme-selector-button-color--light);
$checkbox-color-default-theme: map-get($theme-map-default, $--theme-selector-button-color--light);

.checkbox-field {
  display: flex;
  padding: $space 0;

  &__label {
    margin-left: $space * 2;
  }

  &--disabled {
    opacity: 0.3;
  }
}

input[type='checkbox'].checkbox-field__input {
  flex: 0 0 auto;
  margin-top: $space / 2;
  width: $checkbox-size;
  height: $checkbox-size;
  font: inherit;
  vertical-align: middle;
  appearance: none;
  background: transparent;
  border-radius: 3px;
  box-sizing: border-box;
  border: solid 1px #b3cedb;
  box-shadow: inset 0 0 0 0 $checkbox-color;
  position: relative;
  font-size: $checkbox-size !important;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.2, 0.3, 0, 1), box-shadow 0.3s cubic-bezier(0.2, 0.3, 0, 1),
    border-color 0s linear 0.17s;

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.8);
  }

  &:checked,
  // &:not(:checked),
  &.checkbox-field__input--indeterminate {
    border-color: $checkbox-color;
    box-shadow: inset 0 0 0 0.5em $checkbox-color;
    transition: transform 0.3s cubic-bezier(0.2, 0.3, 0, 1), box-shadow 0.3s cubic-bezier(0.2, 0.3, 0, 1),
      border-color 0s;
  }

  &:before,
  &:after {
    content: '';
    background: $color-white;
    height: $checkbox-size;
    width: $checkbox-size;
    position: absolute;
    transform: translate(0.2728em, 0.4279em) rotate(44.91deg) scale(0.3411, 0.1);
    transform-origin: 0 0;
    transition: transform 0.3s cubic-bezier(0.2, 0.3, 0, 1), opacity 0.3s cubic-bezier(0.2, 0.3, 0, 1);
    top: -1px;
    left: -1px;
  }

  &:after {
    transform: translate(0.8494em, 0.35em) rotate(135.22deg) scale(0.5612, 0.1);
  }

  &:not(:checked):before {
    transform: translate(0.505em, 0.455em) rotate(44.91deg) scale(0, 0);
  }

  &:not(:checked):after {
    transform: translate(0.575em, 0.525em) rotate(135.22deg) scale(0, 0);
  }

  &:indeterminate:before,
  &.checkbox-field__input--indeterminate:before {
    transform: translate(0.25em, 0.45em) rotate(0deg) scale(0.5, 0.1);
  }

  &:indeterminate:after,
  &.checkbox-field__input--indeterminate:after {
    transform: translate(0.75em, 0.55em) rotate(180deg) scale(0.5, 0.1);
  }

  &.checkbox-field__input--default-theme {
    box-shadow: inset 0 0 0 0 $checkbox-color-default-theme;

    &:checked,
    // &:not(:checked),
    &.checkbox-field__input--indeterminate {
      border-color: $checkbox-color-default-theme;
      box-shadow: inset 0 0 0 0.5em $checkbox-color-default-theme;
    }
  }
}
