@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.card {
  border-radius: calculateRem(4px);
  box-shadow:
      1px 2px 2px 0 rgba(39, 47, 56, 0.2),
      2px 2px 5px 0 rgba(39, 47, 56, 0.4);
}