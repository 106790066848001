@import '../../Styles/resources/variables';
@import '../../Styles/resources/theme_variables';
@import '../../Styles/resources/typography_variables';
@import '../../Styles/resources/mixins';

.auth-header {
  &__header-logo-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  &__header-logo {
    max-width: 200px;
  }

  &__header-title {
    margin-top: ($space * 1.5);
  }
}