@import '../../../../modules/Styles/resources/variables';
@import '../../../../modules/Styles/resources/theme_variables';
@import '../../../../modules/Styles/resources/typography_variables';
@import '../../../../modules/Styles/resources/mixins';

.film-analysis {
  display: flex;
  flex-direction: column;

  &__date-selector-ctn {
    z-index: map-get($z-index, date-selector-ctn);
    width: 100%;
    background: linear-gradient(-180deg, rgb(4, 92, 136) 0%, rgb(7, 105, 147) 100%);

    position: -webkit-sticky;
    position: sticky;
    top: $appHeaderHeight;
  }

  &__date-selector {
    width: 100%;
    height: $dateSelectorHeight;
  }

  &__report-indicator {
    padding: calculateRem(6px);
  }

  &__swipe-ctn {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__metric-list-ctn {
    position: relative;
    z-index: map-get($z-index, metric-list-ctn);
    width: 100%;
    box-sizing: border-box;

    // Begining the gradient after 108px so the colors stay the same
    // when the hero metric scrolls behind the date selector
    background: linear-gradient(-180deg, #076993 140px, #0d8eb2 100%);
    padding-bottom: calculateRem(40px); // to account for the slanted title list
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - #{$diagonal-slant}));

    @media (min-width: 769px) {
      clip-path: none;
    }
  }

  .empty-state {
    width: 100%;
    height: 100%;
    color: $text;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__text {
      max-width: 248px;
    }

    &--film-list {
      img {
        height: 142px;
        margin-bottom: $space * 2;
      }
    }

    &--no-presales {
      .icon {
        color: #b3cedb;
        font-size: calculateRem(100px);
        margin-bottom: $space * 2;
        margin-top: $space * 7;
      }
    }
  }
}
