@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.metric-list {
  padding: ($space * 2) 0;

  &-container--pinned {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  &-hero-container {
    .metric-list {
      padding-top: $space;
    }

    &.pin-position {
      position: fixed;
    }
  }

  &-layout--inline {
    position: relative;
    margin-bottom: $space;
  }

  &-layout--block {
    position: relative;
  }

  &__ctn {
    position: relative;
  }

  &__scroll-ctn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__list {
    margin-left: $space;
    margin-right: $space;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .list__item {
    flex: 1;
    flex-shrink: 0;
    min-width: calculateRem(70px);
    display: flex;
    align-items: inherit;

    > .metric-option {
      padding-left: $space / 2;
      padding-right: $space / 2;
    }

    &--is-selectable {
      cursor: pointer;
    }

    &--yearMarketShare {
      min-width: calculateRem(88px);
    }
  }

  .metric-option {
    width: 100%;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    text-align: center;

    &--block {
      flex-direction: column;
    }

    &--inline {
      flex-direction: row;
      justify-content: center;

      .metric-option__value {
        margin-left: $space;
        margin-right: ($space / 2);
      }

      .metric-option__movement {
        margin-left: $space;
      }
    }
  }

  &__scroll-indicator {
    display: flex;
    justify-content: center;
    margin-top: $space;

    position: absolute;
    bottom: -($space);
    left: 50%;
    transform: translateX(-50%);

    .indicator {
      flex: 0 0 auto;
      padding: 0 calculateRem(5px);

      &__item {
        border-radius: 50%;
        height: calculateRem(6px);
        width: calculateRem(6px);
        background: theme-var($--theme-metric-list-scroll-indicator-color--inactive);
        transition: background 300ms ease-in-out;

        &--is-active {
          background: theme-var($--theme-metric-list-scroll-indicator-color--active);
        }
      }
    }
  }
}
