/************************
Fonts
************************/
$sprocket-font-path: '../../fonts' !default;

/************************
Up Icon Font
************************/
@mixin icon($icon: false) {
  font-family: 'upicons';

  @if $icon {
    content: '#{map-get($icons, $icon)}';
  }
}

@font-face {
  font-family: 'upicons';
  font-weight: normal;
  font-style: normal;
  src: url($sprocket-font-path + '/upicons/upicons.eot');
  src: url($sprocket-font-path + '/upicons/upicons.eot?#iefix') format('eot'),
    url($sprocket-font-path + '/upicons/upicons.woff2') format('woff2'),
    url($sprocket-font-path + '/upicons/upicons.woff') format('woff'),
    url($sprocket-font-path + '/upicons/upicons.ttf') format('truetype'),
    url($sprocket-font-path + '/upicons/upicons.svg#upicons') format('svg');
}

[class^='icon--'],
[class*=' icon--'] {
  @include icon;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons: (
  audio-description: '\EA01',
  back-to-top: '\EA02',
  back: '\EA03',
  bookmark: '\EA04',
  check: '\EA05',
  chevron-left: '\EA06',
  chevron-right: '\EA07',
  close: '\EA08',
  contact: '\EA09',
  copy-and-paste: '\EA0A',
  delete: '\EA0B',
  down: '\EA0C',
  download: '\EA0D',
  edit: '\EA0E',
  error-alert: '\EA0F',
  exit: '\EA10',
  expand: '\EA11',
  express: '\EA12',
  favourite: '\EA13',
  films: '\EA14',
  filter: '\EA15',
  hide-password: '\EA16',
  info: '\EA17',
  intercom: '\EA18',
  log-out: '\EA19',
  market: '\EA1A',
  more: '\EA1B',
  refresh: '\EA1C',
  remove: '\EA1D',
  screens: '\EA1E',
  search: '\EA1F',
  settings: '\EA20',
  share: '\EA21',
  show-password: '\EA22',
  special-needs-friendly: '\EA23',
  subtitled-performance: '\EA24',
  territories: '\EA25',
  theatres: '\EA26',
  time: '\EA27',
  unfavourite: '\EA28',
  advanced-sales: '\EA29',
);

@each $icon in $icons {
  $name: nth($icon, 1);
  $content: nth($icon, 2);

  .icon--#{$name}:before {
    content: $content;
  }
}
