@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.rank-label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  background: #fd7e14;
  border-radius: 3px;
  color: $color-white;
  min-width: calculateRem(20px);
  height: calculateRem(20px);
  padding: calculateRem(2px);
}
