@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.pagination {
  background-color: $color-white;
  margin: ($space * 2) 0 0 0;
  padding: $space;
  box-shadow: 0px -1px 2px 0px rgba(39, 47, 56, 0.3), 0px -2px 3px 0px rgba(39, 47, 56, 0.3);
  display: flex;
  align-self: stretch;

  &__left {
    flex: 1;
  }

  &__right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__pages {
    min-width: auto;

    // Hiding select arrow
    -webkit-appearance: none;
    -moz-appearance: none;

    // Align  text center
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;

    // The rest
    font-weight: bold;
    background: theme-var($--theme-button-primary-bg);
    color: theme-var($--theme-button-primary-color);
    padding: 0 $space;
    border-radius: 4px;
    height: 32px;
    line-height: 1;
    overflow: visible;
  }

  .button {
    &--has-icon-left {
      .icon {
        margin-right: $space;
      }
    }

    &--has-icon-right {
      .icon {
        margin-left: $space;
      }
    }

    &--disabled {
      opacity: 0.8;
      color: #6fa1bb;
    }
  }
}
