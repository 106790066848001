@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.table {
  width: 100%;
  position: relative;

  &__th {
    text-align: left;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;

    background: var(--theme-theatre-list-item-table-th-bg);
    // box shadow to mimic border rather than border, as border with position:sticky in tables doesn't work well.
    box-shadow: inset 0 1px 0 var(--theme-theatre-list-item-table-th-border),
      inset 0 -1px 0 var(--theme-theatre-list-item-table-th-border);

    &--sortable {
      .sort-toggle__icon {
        margin-left: ($space / 2);
      }
    }

    * {
      line-height: calculateRem(14px); // bespoke to match particular table design
    }
  }

  &__td {
    border-bottom: 1px solid #dce7ef;
  }

  &__th,
  &__td {
    padding: ($space / 2) $space;
    vertical-align: middle;

    &:first-child {
      padding-left: ($space * 2);
    }

    &:last-child {
      padding-right: ($space * 2);
    }
  }

  &--col-borders {
    .table__th,
    .table__td {
      &:not(:last-child) {
        border-right: 1px solid #dce7ef;
      }
    }
  }
}
