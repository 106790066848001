@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.tabs {
  position: relative;
  z-index: 0;
}

.tabs-tab {
  background-color: inherit;

  &--transition-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}


.tabs__nav {
  &__list {
    display: flex;
    position: relative;
    &__item {
      flex: 1;
    }
  }

  &--material {
    position: relative;

    .tabs__nav {
      &__list {
        display: inline-flex;

        &__item {
          flex: 1;
          white-space: nowrap;
          margin-right: ($space / 2);

          &:last-child {
            margin-right: 0;
          }

          .item__button {
            padding: $space ($space * 2);
            color: #045c88;
          }
        }
      }

      &--active-tab {
        .item__button {
          color: $color-orange;
        }
      }

      &--light-theme {
        .item__button {
          color: #6fa1bb;
        }
        .tabs__nav--active-tab {
          .item__button {
            color: $color-white;
          }
        }
      }
    }
  }

  &__underline {
    $tabs-progress-height: 2px;

    position: relative;
    height: $tabs-progress-height;

    &__indicator {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $color-orange;
      transition: left 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms, width 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
      &--light-theme {
        background-color: $color-white;
      }
    }
  }
}
