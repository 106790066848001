@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

body {
  &.sidebar--is-active {
    overflow: hidden;
  }
}

.app-sidebar {
  $sidebar-width: 312px; // $space * 39
  $siderbar-padding-left: ($space * 3);
  $siderbar-padding-right: ($space * 2);

  $menu-item-icon-color: #6fa1bb;
  $menu-item-icon-color--active: #fd7e14;

  $menu-item-text-color: #2a303e;
  $menu-item-text-color--active: #fd7e14;

  visibility: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  background: $modal-overlay-bg-color;
  bottom: 0;
  overflow-y: auto;
  z-index: map-get($z-index, app-sidebar);

  display: flex;

  &__inner {
    display: flex;
    flex-direction: column;

    width: $sidebar-width;
    box-sizing: border-box;
    padding-top: ($space * 2);
    padding-bottom: ($space * 2);
    background: $color-white;

    min-height: 500px;
    overflow: auto;
  }

  &__overlay {
    flex: 1;
  }

  &__header,
  &__footer {
    flex: 0 0 auto;
    padding-left: $siderbar-padding-left;
    padding-right: $siderbar-padding-right;

    .sidebar-menu__item {
      padding-left: 0;
    }
  }

  &__header {
    .header__logos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ($space * 1.5);

      &__comscore {
        flex: 0 0 auto;
        width: calculateRem(25px);
        height: calculateRem(22px);
      }

      &__showtime {
        width: calculateRem(175px);
        height: calculateRem(14px);
        flex: 0 0 auto;
      }
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: $space;

    &__title {
      padding-left: $siderbar-padding-left;
      padding-right: $siderbar-padding-right;
      flex: 0 0 auto;
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__divider {
    border: none;
    border-bottom: 1px solid #dce7ef;
    margin: 0;
    margin-left: -($siderbar-padding-left);
    margin-right: -($siderbar-padding-right);
  }

  &__menu {
    margin-bottom: $space;
  }

  .sidebar-menu__item {
    display: flex;
    align-items: center;
    padding-top: $space;
    padding-bottom: $space;

    color: $menu-item-text-color;
    text-decoration: none;
    border: none;
    background-color: transparent;

    .icon {
      margin-right: ($space * 1.5);
      color: $menu-item-icon-color;
      font-size: calculateRem(20px);
    }

    &--is-active {
      color: $menu-item-text-color--active;

      .icon {
        color: $menu-item-icon-color--active;
      }
    }
  }

  .sidebar-menu__button {
    padding-top: ($space * 2);
    padding-bottom: ($space * 2);
  }

  .territory-select__search,
  .territory-list {
    padding-left: $siderbar-padding-left;
    padding-right: $siderbar-padding-right;
  }
}
