@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.horizontal-swipe__main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // height: same as content child by JQuery.

  &--mb {
    margin-bottom: $space;
  }
}

.horizontal-swipe__item {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  pointer-events: auto;
  transform-origin: 50% 50% 0;
  border-radius: 4px;
}

.horizontal-swipe__content {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.horizontal-swipe__content:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.horizontal-swipe__icon {
  margin: 0 $space * 2;
  align-self: center;
  color: #2a303e;

  &--xs {
    .icon {
      font-size: calculateRem(26px);
    }
  }
  &--sm {
    .icon {
      font-size: calculateRem(42px);
    }
  }
  &--md {
    .icon {
      font-size: calculateRem(74px);
    }
  }
}
