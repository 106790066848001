@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

// Common styles to all input fields
.input-field {
  // Removes shadow and rounded corners
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @include font-size($type-input);
  line-height: normal; // for iOs devices. line-height: 1 causes issues there.
  flex: 1;

  border-radius: 3px;
  padding-left: ($space * 2);
  padding-right: ($space * 2);
  padding-top: calculateRem(13px);
  padding-bottom: calculateRem(15px);
  background-color: $color-gray;
  border: none;

  &:focus {
    outline: none;
  }

  &__label {
    display: block;
    margin-bottom: calculateRem(3px);
  }

  &--border {
    background-color: transparent;
    border: 1px solid $input-border-color;
    padding-top: calculateRem(12px);
    padding-bottom: calculateRem(14px);

    &:focus {
      border: 1px solid #045c88;
    }
  }

  &--error {
    border: 1px solid $input-border-color-error;
    background-color: $input-bg-color-error;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__input-container {
    display: flex;
    flex: 1;
  }

  &__subtext {
    color: #045c88;
  }

  &__search {
    position: relative;

    &__icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: $space * 2;
      margin: auto auto;
      color: #6fa1bb;
      display: flex;
      align-items: center;
      font-size: calculateRem(20px);
    }

    .input-field {
      padding-left: $space * 5;
    }

    ::placeholder {
      color: $color-dark-gray;
    }
  }
}

// Password-field specific
.password-field {
  .input-field {
    padding-right: ($space * 6);
  }

  &__toggle-password {
    margin-left: -($space * 6);
    padding: 0 ($space * 2);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon {
      color: #6fa1bb;
    }
  }
}
