@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.loading--spinner {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .spinner {
    box-sizing: border-box;
    border-radius: 100%;
    border-style: solid;
    animation: spin 1s infinite linear;
    border-color: theme-var($--theme-spinner-spin-bg-color);
    border-top-color: theme-var($--theme-spinner-bg-color);

    &--size {
      &-xs {
        width: 20px;
        height: 20px;
        border-width: 2px;
      }
      &-s {
        width: 32px;
        height: 32px;
        border-width: 3px;
      }
      &-m {
        width: 48px;
        height: 48px;
        border-width: 5px;
      }
    }

    &--default-theme {
      border-color: map-get($theme-map-default, $--theme-spinner-spin-bg-color);
      border-top-color: map-get($theme-map-default, $--theme-spinner-bg-color);
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
