@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

  .theatres__selectors-ctn {
    padding: $space ($space * 2);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .selector-button {
      margin: $space 0;
      text-transform: capitalize;
    }
    .selector-button:only-child{
      margin-left: auto;
    }

    &--centered {
      justify-content: center;
    }
  }
