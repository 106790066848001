@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

/************************
See icons.scss for implementation of iconFont.
************************/

/************************
UpIconFont icons
************************/
.icon {
  display: block;
  // Setting to size 18px to match designs. iconFont icons have a slight gap between icon and edge.
  font-size: calculateRem(18px);
}

/************************
Custom Icons
For icons that can't be saved into the iconfont.
For e.g those that have different coloured paths.
************************/
.icon-custom {
  display: block;

  background-repeat: no-repeat;
  background-position: center;
  width: calculateRem(16px);
  height: calculateRem(16px);
  // font-icons have a slight gap between icon and edge.
  // This is to ensure custom and iconFont ones render the same size.
  margin: 1px;

  &--menu {
    background-image: url(#{$image-path}icons/menu.svg);
  }

  &--comscore-logo {
    background-image: url(#{$image-path}icons/comscore-logo.svg);
  }

  &--showtime-logo {
    background-image: url(#{$image-path}icons/showtime-logo.svg);
  }

  &--comscore-logo-with-bg {
    background-image: url(#{$image-path}icons/comscore-logo-with-bg.svg);
  }
}
