@import '../../../Styles/resources/variables';
@import '../../../Styles/resources/theme_variables';
@import '../../../Styles/resources/typography_variables';
@import '../../../Styles/resources/mixins';

.planner-view-modal {
  height: 100%;
  display: flex;

  &--container {
    border-radius: 5px;
    background-color: $color-white;
  }

  .screen {
    margin-bottom: $space;

    &__header {
      width: 100%;
      height: 120px;
      position: relative;

      // Background no image
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: theme-var($--theme-selector-button-bg-color--light);

      .screen-image {
        width: 100%;
        height: 100%;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }

      .close-button {
        position: absolute;
        right: 0;
        top: 0;
        color: theme-var($--theme-button-primary-bg);

        .icon {
          font-size: calculateRem(35px);
        }
      }
    }

    &__title {
      padding: $space * 2;
      background-color: $color-white;
      border-bottom: 1px solid theme-var($--theme-drawer-contents-border-color);
    }

    &__timetable {
      overflow: auto;
      max-height: 40vh;

      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 $space;
        padding: $space ($space * 2);
      }

      thead {
        color: #9569c1;
      }

      th {
        text-align: left;
        position: sticky;
        position: -webkit-sticky;
        background-color: $color-white;
        top: 0;

        &:nth-child(1) {
          width: 30px;
        }
        &:nth-child(2) {
          width: 50px;
        }
        &:nth-child(3) {
          width: auto;
        }
        &:last-child {
          width: 40%;
          text-align: right;
        }
      }

      td {
        &:first-child {
          vertical-align: -webkit-baseline-middle;
        }
        &:last-child {
          text-align: right;
        }
      }

      &__no-result {
        text-align: center !important;
      }

      .icon {
        color: theme-var($--theme-button-primary-bg);
        margin-top: calculateRem(3px);
      }
    }
  }

  .performance {
    margin-bottom: $space;

    &__header {
      width: 100%;
      height: 120px;
      position: relative;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color: theme-var($--theme-selector-button-bg-color--light);

      .screen-image {
        width: 100%;
        height: 100%;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        object-fit: cover;
      }

      .close-button {
        position: absolute;
        right: 0;
        top: 0;
        color: theme-var($--theme-button-primary-bg);

        .icon {
          font-size: calculateRem(35px);
        }
      }
    }

    // Title name, duration, distributor, runweek info
    &__title {
      padding: ($space * 2) ($space * 1.5);
      background-color: $color-white;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: space-between;
    }

    &__name,
    &__info {
      padding-left: $space / 2;
      padding-right: $space / 2;
    }

    &__info {
      flex: 3;
      display: flex;
      justify-content: space-between;
      min-width: 50%;

      p:last-child {
        padding-left: $space / 2;
        text-align: right;
      }
    }

    &__performance-data {
      overflow: auto;
      max-height: 40vh;
      margin-top: 0;
    }

    // Screen time and screen info
    &__screen-time {
      display: flex;
      padding: 0 ($space * 2);
    }

    &__date-time,
    &__screen-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        color: theme-var($--theme-button-primary-bg);
      }
    }

    &__screen-info {
      margin-left: ($space * 3);
    }

    &__time-text,
    &__screen-text {
      margin-left: $space;
    }

    // performance attributes
    &__list-attributes {
      padding: $space * 2;

      .icon-attribute {
        color: #7b47af;
        margin-right: $space;
        margin-top: 2px;
      }
    }

    &__attribute-item {
      display: flex;
      align-items: center;
      margin: ($space * 0.5) 0;
    }

    // metrics
    &__list-metrics {
      border-top: 1px solid theme-var($--theme-drawer-contents-border-color);
      padding: $space ($space * 2);
    }

    &__metric-item {
      display: flex;
      justify-content: space-between;
      padding: ($space * 0.5) 0;
    }

    &__metric-label {
      flex: 1;
    }

    &__metric-values {
      display: flex;
      flex: 1;
      align-items: baseline;
    }

    &__metric-percentage,
    &__metric-capacity {
      margin-left: $space;
    }
  }
}
