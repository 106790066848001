@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.radio-group {

  &__label {
    display: block;
    margin-bottom: calculateRem(16px);
  }
}
