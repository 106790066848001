@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.occupancy-percentage {

  &__text {
    &--success {
      color: #3cd81c;
    }
    &--danger {
      color: #ff8c8d;
    }
    &--warning {
      color: #f9da14;
    }
  }
}

