:root.theme--default {
  @include spread-map($theme-map-default);
}

:root.theme--films {
  @include spread-map($theme-map-films);
}

:root.theme--theatres {
  @include spread-map($theme-map-theatres);
}

:root.theme--markets {
  @include spread-map($theme-map-markets);
}

:root.theme--advanced-sales {
  @include spread-map($theme-map-default);
}

