@import '../../../../modules/Styles/resources/variables';
@import '../../../../modules/Styles/resources/theme_variables';
@import '../../../../modules/Styles/resources/typography_variables';
@import '../../../../modules/Styles/resources/mixins';

.film-metadata {
  display: flex;
  &__poster-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    .poster-bg {
      &__top-ctn {
        overflow: hidden;

        &--gradient {
          min-height: 223px;
          background-image: linear-gradient(
            179deg,
            rgba(13, 142, 178, 0.35) 0%,
            rgba(8, 115, 155, 0.65) 26%,
            rgba(4, 92, 136, 0.9) 54%,
            #045c88 100%
          );
        }

        &--poster {
          max-height: 223px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(
              180deg,
              rgba(13, 142, 178, 0.35) 0%,
              rgba(8, 115, 155, 0.65) 26%,
              rgba(4, 92, 136, 0.9) 54%,
              #045c88 100%
            );
          }
        }
      }

      &__image {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }

    .bg__gradient {
      flex: 1;
      background-image: linear-gradient(180deg, #045c88 150px, #0d8eb2 100%);

      &--with-poster {
        background-image: linear-gradient(180deg, #045c88 0%, #0d8eb2 100%);
      }
    }
  }

  &__back {
    color: $color-white;
  }

  &__content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__nav {
    padding: $space ($space * 2) ($space / 2) ($space * 2);
    box-shadow: none;

    &__switch-ctn {
      display: flex;
      justify-content: center;
      margin-top: $space;
    }
  }

  &__view {
    max-width: 450px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;

    &--comps {
      padding: 0 ($space * 2) ($space * 8) ($space * 2);
    }

    &--theatres {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &--not-available {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .not-available-state {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__text {
          max-width: 75%;
          text-align: center;
        }
      }
    }
  }

  .title {
    margin-top: -($space);
  }

  .title:nth-of-type(2) {
    margin-top: 0;
    text-transform: capitalize;
  }

  &__theatres-list {
    padding-top: ($space * 2);
  }

  &__report-indicator {
    margin-bottom: ($space * 2);
    margin-left: -$space;
    margin-right: -$space;
  }

  .comps__toggle {
    display: flex;
    justify-content: center;
    margin-bottom: $space * 3;
  }

  .comps__list {
    margin-top: ($space * 3);
    position: relative;
    z-index: 0;
  }

  .comps__chart {
    position: relative;
  }

  &--circuits {
    .film-metadata__nav {
      position: -webkit-sticky;
      position: sticky;
      top: $appHeaderHeight;
      z-index: 10;
      background: #045c88;
      padding-bottom: ($space * 2);
    }
  }
}
