@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.comps-chart {
  $chart-height: 180px;

  position: relative;

  &__embedded {
    height: $chart-height;
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-chart {
      flex: 1;
      width: 100%;
    }

    &__button-close {
      position: absolute;
      right: calculateRem(-10px);
      top: calculateRem(6px);
      color: $color-white;

      .icon {
        font-size: calculateRem(20px);
      }
    }

    &-container {
      &.overlay {
        background: linear-gradient(-180deg, rgb(4, 92, 136) 0%, rgb(13, 142, 178) 100%);
        padding: 0 $space * 2;
        padding-bottom: $space * 4;
        backdrop-filter: none;
        top: $appHeaderHeight;
        height: auto;
        bottom: 0;

        .comps-metric-toggle {
          margin-top: ($space * 4);
        }
      }
    }

    &__loading {
      position: absolute;
      bottom: 40%;
      right: 0;
      left: 0;
    }
  }

  &__tooltip {
    li {
      display: flex;
      align-items: center;
    }

    &--value {
      margin-right: $space;
    }
  }

  &__legend {
    background-color: #034b6f;
    color: $color-white;
    border-radius: 4px;
    padding: $space * 2;
    margin-top: $space * 2;
    margin-bottom: $space;
    align-self: stretch;

    &__heading {
      text-align: center;
      margin-bottom: $space;
    }

    &__table {
      width: 100%;

      &__td--right {
        text-align: right;
      }

      &__td--center {
        text-align: center;
      }

      &__td--left {
        max-width: calculateRem(144px);
      }

      .skeleton {
        @include skeleton(56px);
        margin: 0 0 0 auto;
      }
    }

    &__film-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__legend-marker {
    $marker-size: $space;

    width: $marker-size;
    height: $marker-size;
    border-radius: $space;
    margin-right: $space;
  }

  &__expand-button {
    position: absolute;
    right: 0;
    top: -32px;
    color: $color-white;
  }

  &__loading {
    height: $chart-height;
  }
}
