@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.overlay {
  position: fixed; // cover the view regardless of scroll position;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: $modal-overlay-bg-color;

  // Not supported by 100% of browsers
  // The few that don't support will have to settle for just the background set.
  backdrop-filter: blur($space);
  z-index: map-get($z-index, app-body-overlay);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  margin: auto auto;
  max-width: $app-max-width;

  &__content {
    overflow: hidden;
  }

  /*
   * Full overlay's content takes up all the space at all times.
   * overflow should be hidden & child should do scroll if required.
   */
  &--full {}
  &--full > &__content {
    flex: 1;
  }

  /*
   * Modals are centered horizontally
   * Vertical alignment is a bit skewed - modal is placed slightly higher than vertical center.
   * Children of content block defines their own dimensions (e.g. through content volume).
   * 100% - ($space * 2) is still the absoulute boundary of each dimension
   * overflow should be hidden & child should do scroll if required.
   */
  &--modal {
    padding: ($space * 2);
    align-items: center;

    &:before,
    &:after {
      min-height: 0;
      content: '';
    }

    // Dummy vertical top spacing.
    &:before {
      flex: 1;
    }

    // Dummy vertical bottom spacing.
    &:after {
      flex: 2;
    }
  }
  &--modal > &__content {}

  /*
   * It allows to show the app header
   */
  &--show-app-header {
    top: $appHeaderHeight;
  }
}

.overlay-content {
  overflow: hidden;

  &--scrollable {
    overflow: auto;
  }

  @at-root .overlay--modal & {
    max-width: 100%;
    max-height: 100%;
  }

  @at-root .overlay--full & {
    width: 100%;
    height: 100%;
  }
}
