@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.reporting-indicator {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .zone {
    padding: 0 $space;
  }

  &__display-reported {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: ($space / 2) 0;

    .display-reported-item {
      flex: 1;
      width: 100%;
      display: flex;

      &:not(:last-child) {
        margin-bottom: $space;
      }

      .reported-item-icon {
        width: calculateRem(24px);
        height: calculateRem(24px);
        margin-right: $space;
      }
    }
  }

  &__last-updated {
  }

  &__overlay-toggle {
    display: block;
    color: $color-white;
    padding: 0;

    .reporting-indicator__display-reported {
      padding: 0;
    }
  }

  &__overlay {
    &-content {
      background: $color-white;

      .content__header {
      }

      .content__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 0;

        &__search {
          margin: $space 0;
        }
      }
    }
  }

  &__table {
    table-layout: fixed;
    // height: 100%;

    &-ctn {
      flex: 1;
      margin-left: -($space * 2);
      margin-right: -($space * 2);
      position: relative;
      display: flex;
      margin-top: $space;

      &-inner {
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &__empty {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__loader {
        position: absolute;
        background: rgba($color-white, 0.75);
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        transition: opacity 150ms ease-in-out;
        pointer-events: auto;
        visibility: hidden;

        &--is-loading {
          opacity: 1;
          visibility: visible;
          pointer-events: none;
        }
      }
    }

    .feed-icon {
      width: calculateRem(24px);
      height: calculateRem(24px);
    }

    .table__th,
    .table__td {
      &:first-child {
        padding-left: ($space * 1.5);
      }

      &:last-child {
        padding-right: ($space * 1.5);
      }
    }

    .td--circuitName p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__pagination {
    margin-left: -($space * 2);
    margin-right: -($space * 2);
    margin-top: 0;
  }
}
