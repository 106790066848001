@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.app-header {
  position: fixed;
  top: 0;
  z-index: map-get($z-index, app-header);
  height: $appHeaderHeight; // TODO: This does not work with potentially multiline content
  width: 100%;
  background-color: $color-white;
  padding: 0 $space;
  box-sizing: border-box;

  .page-title {
    text-align: center;
    margin: 0 auto;
  }

  .refresh-button,
  .share-toggle,
  .search-toggle,
  .sidebar-toggle,
  .sites-toggle,
  .header-misc-actions__button {
    @media (max-width: 359px) {
      padding-left: $space / 2;
      padding-right: $space / 2;
    }
  }
}
