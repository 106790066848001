/*************************
calculateRem - Font size px to rem
*************************/
@function calculateRem($sizes) {
  $rems: ();

  @each $size in $sizes {
    // If you happen to pass rem already just return it
    @if unit($size) == 'rem' {
      $rems: append($rems, $size);
    } @else {
      // convert to px and divide by 16px (converts to unitless)
      $remSize: (0px + $size) / 16px;
      // convert unitless to rem
      $rems: append($rems, $remSize + 0rem);
    }
  }

  @if (length($rems) > 1) {
    @return $rems;
  } @else {
    // Unpack if it's a single item
    @return nth($rems, 1);
  }
}

/*************************
font-size - Takes px size value, converts to REM
Also adds default in px for older browsers.
*************************/
@mixin font-size($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

/*************************
spread-map
*************************/
@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

@function theme-var($key, $fallback: null, $map: $theme-map-films) {
  @if not map-has-key($map, $key) {
    @error "key: '#{$key}', is not a key in map: #{$map}";
  }
  @if ($fallback) {
    @return var($key, $fallback);
  } @else {
    @return var($key);
  }
}

@mixin flex-full-container($flex-direction: column) {
  display: flex;
  flex: 1;
  flex-direction: unquote($flex-direction);

  @if (str-index($flex-direction, 'column')) {
    min-height: 0;
  }

  @if (str-index($flex-direction, 'row')) {
    min-width: 0;
  }
}

/*************************
This provides a Skeleton frame for values, you can customize it by these three params.
*************************/
@mixin skeleton($width: auto, $height: 14px, $background: $color-white) {
  opacity: 0.1;
  width: $width;
  height: $height;
  margin: 8px 16px;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: $background;
}

/*************************
hide-scroll - Hides scrollbar on multiple browsers
*************************/
@mixin hide-scrollbar() {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
