@import '../../Styles/resources/variables';
@import '../../Styles/resources/theme_variables';
@import '../../Styles/resources/typography_variables';
@import '../../Styles/resources/mixins';

.auth-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;

  &__top-image-container {
    clip-path: polygon(0 0, 100% 0, 100% 96%, 0 82%);
    overflow: hidden;
    position: relative;
    height: 275px;
  }

  &__top-image {
    height: 100%;
    transform: translateX($space * 10);
    display: block;
    margin-left: auto;
    position: absolute;
    right: 0;
  }

  &__header {
    margin-top: ($space * 6);
    margin-bottom: ($space * 4);
    padding: 0 ($space * 2);

    &--no-margin {
      margin-top: 0;
    }
  }
}
