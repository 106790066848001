@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.text {
  &--success {
    color: $color-success;

    .percentage__arrow {
      color: $color-success;
    }
  }
  &--danger {
    color: $color-danger;

    .percentage__arrow {
      color: $color-danger;
    }
  }

  &--warning {
    color: $color-warning;
  }

  &-number {
    display: flex;
    align-items: center;
    font-weight: $type-weight-semibold;

    .percentage__arrow {
      margin-right: $space / 2;
    }

    &--xs {
      @include font-size($type-body-m);
      line-height: $lh-type-body-m;
    }
    &--xxs {
      @include font-size($type-body-s);
      line-height: $lh-type-body-s;
    }
  }
}
