@import '../../Styles/resources/variables';
@import '../../Styles/resources/theme_variables';
@import '../../Styles/resources/typography_variables';
@import '../../Styles/resources/mixins';

.slider-wrapper {
  height: 100%;

  .keen-slider {
    position: relative;
    height: 100%; // important to keep so keen-slider can calculate via js.

    &__slide {
      cursor: grab;
      position: absolute;
      overflow: visible; // changed to visible so strings for 'Tomorrow' and 'Yesterday' are not being cut off at its beginning and end
      min-height: 100%;
      display: flex;
      will-change: transform;
      justify-content: center;
    }
  }

  .slide__content {
    width: 100%;
    display: flex;
  }
}