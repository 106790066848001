@import '../../../../modules/Styles/resources/variables';
@import '../../../../modules/Styles/resources/theme_variables';
@import '../../../../modules/Styles/resources/typography_variables';
@import '../../../../modules/Styles/resources/mixins';

.theatre-analysis {
  display: flex;
  flex-direction: column;

  &__nav-header {
    margin: $space ($space * 2) 0;
  }

  &__back {
    color: $color-white;
  }

  &__swipe-ctn {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__actions-ctn {
    position: -webkit-sticky;
    position: sticky;
    top: $appHeaderHeight;
    z-index: 11;
    width: 100%;

    background: linear-gradient(-180deg, rgb(111, 55, 166) 0%, rgb(106, 50, 161) 100%);
    padding-bottom: calculateRem(42px); // to account for the slanted title list
  }

  &__date-selector {
    width: 100%;
    height: $dateSelectorHeight;
  }

  &__site-list {
    padding-top: ($space * 2);
    max-width: calculateRem(450px);
    margin: 0 auto;
  }
}
