@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.arrow {
  color: theme-var($--theme-arrow-color);

  i {
    font-size: calculateRem(10px);
  }

  &--up {
    transform: rotate(180deg);
  }

  &--right {
    transform: rotate(-90deg);
  }

  &--left {
    transform: rotate(90deg);
  }
}
