@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.date-comparison-period {
  width: 100%;
  text-align: center;
  padding: $space / 2 0;
  border-bottom: 1px solid var($--theme-date-selector-border-color);
}
