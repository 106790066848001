@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.date-selector {
  position: relative;
  height: $dateSelectorHeight;
  width: 100%;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  border-bottom: 1px solid theme-var($--theme-date-selector-border-color);

  &--empty {
    opacity: 0;
  }

  &__subtext {
    position: absolute;
    bottom: 2px;
    width: 100%;
    pointer-events: none;
    text-align: center;
  }

  &__item {
    flex: 1;
    border: none;
    background: none;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: theme-var($--theme-date-selector-color-inactive);
    transition: color 300ms ease-in-out;

    &--active {
      color: theme-var($--theme-date-selector-color-active);
    }

    &__date {
      margin-bottom: calculateRem(6px);
    }

    &__week-day {
      bottom: 6px;
      position: absolute;

      &--weekend {
        color: #fd7e14;
      }
    }
  }
}



