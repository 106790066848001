@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.radio-button {
  $radio-size: calculateRem(16px); // size square
  $radio-checked-size: calculateRem(8px);
  $radio-transition: cubic-bezier(0.78, 0.14, 0.15, 0.86);

  display: flex;
  align-items: center;
  cursor: pointer;

  &__input {
    flex: 0 0 auto;
    position: relative;

    &--real {
      position: absolute;
      width: $radio-size;
      height: $radio-size;
      opacity: 0;
    }

    &--fake {
      position: relative;
      display: block;
      width: $radio-size;
      height: $radio-size;
      box-sizing: border-box;

      border-width: calculateRem(1px);
      border-style: solid;
      border-radius: 50%;
      border-color: theme-var($--theme-radio-border-color);
      background-color: theme-var($--theme-radio-bg-color);

      &:before {
        content: ' ';
        position: absolute;
        width: $radio-checked-size;
        height: $radio-checked-size;
        left: 3px;
        top: 3px;
        border-radius: 50%;
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.3s $radio-transition, transform 0.3s $radio-transition;
      }

      &.is--checked {
        border-color: theme-var($--theme-radio-border-color--checked);
        background-color: theme-var($--theme-radio-bg-color--checked);

        &:before {
          background-color: theme-var($--theme-radio-border-color--checked);
          opacity: 1;
          transform: scale(1);
          animation-fill-mode: both;
        }
      }
    }
  }

  &--default-theme {
    .radio-button__input--fake {
      border-color: map-get($theme-map-default, $--theme-radio-border-color);
      background-color: map-get($theme-map-default, $--theme-radio-bg-color);

      &.is--checked {
        border-color: map-get($theme-map-default, $--theme-radio-border-color--checked);
        background-color: map-get($theme-map-default, $--theme-radio-bg-color--checked);

        &:before {
          background-color: map-get($theme-map-default, $--theme-radio-border-color--checked);
        }
      }
    }
  }

  &--space-between {
    justify-content: space-between;
  }

  *:first-child {
    margin-right: $space;
  }
}
