@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.league-table-chart {
  width: 100%;

  &__label {
    padding: ($space/2) ($space/2) ($space/2) 0;
    vertical-align: middle;

    &__text {
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__bar {
    width: 50%;
    vertical-align: middle;
    padding-right: $space * 2;
  }

  &__value {
    width: 15%;
    overflow: hidden;
    text-align: right;
    vertical-align: middle;
  }
}
