@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.bookmarks {
  &__toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    .button {
      margin: auto $space;
    }
  }

  &__header {
    padding-left: $space;
    padding-right: $space;
    border-bottom: 1px solid #dce7ef;
  }

  &__title {
    text-align: center;
  }

  &-wrapper {
    height: 100%;

    &__page {
      box-shadow: 0 0 0 4px #dce7ef;
      background-color: white;
    }
  }

  &__list {
    .input-field__search {
      flex: 0 0 auto;
      margin: $space * 2;
    }

    &__tabs-nav {
      margin: $space auto 0 auto;
      height: fit-content;
      .button {
        text-transform: uppercase;
      }
    }

    &__items {
      height: 100%;
      flex: 1 1 auto;
    }

    &__item {
      padding: $space * 2;
      background-color: $color-white;
    }

    &__item-divider {
      border-bottom: 1px solid #dce7ef;
    }

    &__actions {
      &--edit {
        background-color: #51a8dd;
      }

      &--delete {
        background-color: #f15e5e;
      }
    }

    &__no-result {
      text-align: center;
    }

    &__loading-main {
      height: 100%;
    }

    &__loading-lazy {
      margin-top: $space;
      margin-bottom: $space;
    }
  }

  &__form {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    &__fields {
      flex: 1 1 auto;
      padding: $space * 2;
    }

    &__footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: $space * 2;
      border-top: 1px solid #dce7ef;
    }

    &--name {
      margin-bottom: $space * 3;
    }

    .radio-button {
      margin-bottom: $space * 2;
    }
  }

  &__delete-confirm {
    .drawer__content {
      padding: $space * 2;
    }
    // Todo implement more types of buttons as info/danger...
    .button--primary {
      background: #f15e5e;
    }
  }

  &__footer {
    padding: ($space * 2) ($space * 2) 20px ($space * 2);
    border-top: 1px solid #dce7ef;
    .button {
      width: 100%;
      margin: 0;
    }
  }
}
