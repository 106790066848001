@import '../../../modules/Styles/resources/variables';
@import '../../../modules/Styles/resources/theme_variables';
@import '../../../modules/Styles/resources/typography_variables';
@import '../../../modules/Styles/resources/mixins';

.auth-login, 
.auth-reset,
.auth-forgot {
  &__form-field {
    margin-bottom: ($space * 2)
  }

  &__form-group {
    padding: ($space * 2);
  }
}

.auth-reset {
  &__title {
    padding: 0 ($space * 2);
    margin-top: ($space * 2);
  }
}

.auth-login {
  &__reset-success-message {
    padding: 0 ($space * 2);
  }

  &__title {
    margin-top: ($space * 2);
    margin-bottom: ($space);
  }
}

.auth-forgot {
  &__text {
    padding: 0 ($space * 2);
  }

  &__title {
    margin-top: ($space * 2);
  }

  &__paragraph {
    margin-bottom: ($space * 3); 
  }
}

.auth-link {
  text-decoration: none;
  color: #116895;
  text-align: center;
  display: flex;
  justify-content: center;
  flex: 1;
}

.auth-button {
  width: 100%;
  margin: 0;
  margin-top: ($space * 2);
  padding: ($space * 1.5) 0;  
}