@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.app-main {
  margin-top: $appHeaderHeight; // TODO: This does not work with potentially multiline header content
  flex: 1;
  position: relative;
  display: flex;
}