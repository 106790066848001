@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.header-misc-actions {
  &__menu {
    display: flex;
    flex-direction: column;
    border-radius: 3px;

    &.element-popover__content {
      // Overwriting popover component default styles
      margin-top: $space * 2;
    }

    // Spaces above and below this menu.
    &:before {
      content: '';
      height: 12px;
    }
    &:after {
      content: '';
      height: 12px;
    }
  }
}
