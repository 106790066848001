@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.planner-view {
  // Colors
  $color-time-line: #62279d;
  $color-performance-card: #722db6;

  // Dimensions
  $time-height: 32px;
  $screen-card-height: 48px;
  $screen-card-width: $screen-card-height;
  $screens-sidebar-width: $screen-card-width + ($space * 2) + $space; // width + margin left-right

  // x-index
  $z-index-timeline: 10;
  $z-index-screens: 15;
  $z-index-current-time-divider: 12;

  /**
 * This variable here has JSX dependency, please change in both places
 * @constant GAP_HOUR_TIME_LINE
 * @file @modules/PlannerView/constants.js
 * */
  $time-gutter: 80px;

  height: 100%;
  display: flex;
  flex-direction: column;

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 $space 0 $space;
    margin-bottom: $space;
    margin-top: $space;
    position: relative;
    height: calculateRem(40px);

    .expand-button,
    .close-button {
      width: fit-content;
      color: $color-white;
      padding: calculateRem(6px);

      .icon {
        font-size: calculateRem(28px);
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    .container-y {
      flex: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;

      &__content {
        min-height: 100%;
        display: flex;
        flex: 1;
      }
    }

    .container-x {
      flex: 1;
      position: relative;
      display: flex;

      // Requires overflow auto to scroll to current time in JS
      // overflow: auto;

      &__content {
        display: flex;
        flex-direction: column;
      }
    }

    .time-line {
      display: inline-flex;
      align-items: center;
      height: $time-height;
      padding-left: $space;
      background-color: $color-time-line;
      z-index: $z-index-timeline;
      position: -webkit-sticky;
      position: sticky;
      top: 0;

      &--hour {
        width: $time-gutter;
      }
    }

    .screens {
      left: 0;
      flex: 0 0 auto;
      position: sticky;
      position: -webkit-sticky;
      width: $screens-sidebar-width;
      z-index: $z-index-screens;
      box-shadow: 1px 3px 7px 0 rgba(39, 47, 56, 0.7);

      /* Same gradient as the app has, but starting from the right color percentage. */
      /* 0% here can be 30% by app's gradient */
      background: linear-gradient(#7e43b3 0%, #b26fe0 50%);

      &__header {
        height: $time-height;
        background-color: $color-time-line;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
      }

      &__screen-card {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: $space $space $space ($space * 2);
        height: $screen-card-height;
        width: $screen-card-width;
        border-radius: 3px;
        background-color: $color-performance-card;
        box-shadow: 0 1px 2px 0 rgba(39, 47, 56, 0.3), 0 2px 3px 0 rgba(39, 47, 56, 0.3);
      }
    }

    .performance {
      display: grid;
      grid-gap: $space;
      grid-auto-rows: $screen-card-height;
      margin-top: $space;
      margin-left: $space;

      &__row {
        position: relative;
        display: inline-flex;
      }

      &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        height: $screen-card-height;
        background-color: #62279d;
        border-radius: 3px;
        box-sizing: border-box;

        &__title,
        &__occupancy {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: auto $space;
        }

        &--selected {
          border: 2px solid #f1ecf7;
        }

        // Default styles, changed by elementRef in hook 'usePerformanceCardsPositioning'.
        left: 0;
        width: auto;
      }
    }

    &__no-data {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__current-time-divider {
    height: 100%;
    top: 0;
    left: 0; // Calculated by useRef
    position: absolute;
    border: 1px dashed #fc7d14;
    z-index: $z-index-current-time-divider;

    // Sidebar's margin and $space that has the timeLine. Then the time setup for this line would match perfectly.
    // This element starts counting pixels from left: 0 in pannerView.
    margin-left: $screens-sidebar-width + $space;
  }

  &__overlay {
    overflow: auto;
    display: flex;
    flex-direction: column;
    color: $color-white;
    background: linear-gradient(-180deg, rgba(#6a32a1, 1) 0%, rgba(#b270e0, 1) 100%);

    // As e use showAppHeader at Overlay component
    // we have to remove the same amount from the content's height.
    height: calc(100% - #{$appHeaderHeight}) !important;
  }
}
