@import '../../../Styles/resources/variables';
@import '../../../Styles/resources/theme_variables';
@import '../../../Styles/resources/typography_variables';
@import '../../../Styles/resources/mixins';

.user-settings-password {
  display: flex;
  height: 100%;

  .updating-password {
    opacity: 0.5;
    pointer-events: none
  }

  &__container {
    padding: $space*2;
  }

  &__footer {
    padding: $space*2;

    &--submit-btn {
      width: 100%;
      height: 48px;
    }
  }

  .button--primary {
    background: map-get($theme-map-films, $--theme-button-primary-bg);
    color: map-get($theme-map-films, $--theme-button-primary-color);
  }
}
