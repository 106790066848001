@import 'resources/variables';
@import 'resources/theme_variables';
@import 'resources/typography_variables';
@import 'resources/mixins';

// Imports

@import 'normalise';
@import 'reset';
@import 'type';
@import 'icons';
@import '_themes.scss';

// Custom Styles

html {
  min-height: 100%;
  width: 100%;
  display: flex;
}

body,
#app-root {
  display: flex;
  flex: 1;
}

#app-root {
  flex-direction: column;
  max-width: $app-max-width;
  min-width: $app-min-width;
  margin-left: auto;
  margin-right: auto;
}

body {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;

  &.body--hide-overflow {
    overflow: hidden;
  }
}
