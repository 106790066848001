@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.search-input-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  border-radius: 0;
  background-color: $color-white;
  height: $appHeaderHeight;

  .input-field {
    padding-right: 0;
    height: $appHeaderHeight;
    background-color: $color-white;

    &__search {
      flex: 1;
    }
  }

  .button {
    margin-right: $space;
  }
}
