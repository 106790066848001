@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.site-attribution-selector {

  &__heading {
    color: theme-var($--theme-selector-button-color--light);
  }

  &__list {
    padding: ($space * 2) 0;

    &-item {
      padding: $space 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: capitalize;

      .icon {
        font-size: calculateRem(20px);
      }

      &--selected {
        font-weight: $type-weight-semibold;
        color: theme-var($--theme-selector-button-color--light);
      }
    }
  }
}
