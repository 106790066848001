@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.refresh-button {
  position: relative;

  &--transition {
    transition: transform 500ms linear !important;
  }

  &--rotated {
    transform: rotate(360deg);
  }

  &--active {
    &:after {
      content: '';
      position: absolute;
      top: 5px;
      right: 5px;
      width: 7px;
      height: 7px;
      border-radius: 3px;
      background-color: #f15e5e;
    }
  }
}
