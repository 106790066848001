@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.range-slider {
  $handle-height: 30px;
  $track-height: 12px;

  &__label {
    padding-bottom: $space * 1.5;
    display: block;
  }

  &__slider {
    height: calculateRem($handle-height);
    margin-top: calculateRem(30px);
  }

  &__slider-inner {
    position: relative;
    height: 100%;
    margin: 0 calculateRem(($handle-height / 2));
  }

  &__rail {
    height: calculateRem($track-height);
    position: absolute;
    top: calculateRem(4px);
    left: 0;
    right: 0;
    transform: translateY(50%);
    margin-left: calculateRem(-($handle-height / 2));
    margin-right: calculateRem(-($handle-height / 2));
    background-color: theme-var($--theme-range-slider-rail-bg); 
    border-radius: 4px;
  }

  &__track {
    height: calculateRem($track-height);
    background-color: theme-var($--theme-range-slider-track-bg); 
    position: absolute;
    top: calculateRem(4px);
    left: 0;
    right: 0;
    transform: translateY(50%);
  }

  &__handle {
    width: calculateRem($handle-height);
    height: calculateRem($handle-height);
    position: absolute;
    top: 0;
    right: auto;
    transform: translateX(-50%);

    &__label {
      color: theme-var($--theme-range-slider-handle-label-color);
      position: absolute;
      top: calculateRem(-32px);
      transform: translateX(-50%);
      left: 50%;
    }

    &__circle {
      fill: theme-var($--theme-range-slider-handle-fill-color);
      stroke: theme-var($--theme-range-slider-handle-stroke-color);
    }
  }
}