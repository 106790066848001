@import '../../../Styles/resources/variables';
@import '../../../Styles/resources/theme_variables';
@import '../../../Styles/resources/typography_variables';
@import '../../../Styles/resources/mixins';

.user-settings-default-territory {
  &__current {
    display: flex;
    justify-content: space-between;
    color: #045c88;
  }

  &__title {
    color: #fd7e14;
    text-transform: uppercase;
    margin-bottom: $space;
  }

  &__item-territory {
    margin-bottom: $space * 2;
  }

  &__search {
    margin-bottom: $space * 2;
  }

  &__list {
    overflow-y: auto;

    .item-territory {
      cursor: pointer;
      margin-bottom: $space * 2;
    }

    &--no-data {
      text-align: center;
    }
  }

  .section {
    padding-bottom: $space * 2;
  }

  .section-container {
    padding: $space ($space * 2);
  }

  &__loading {
    opacity: 0.5;
    pointer-events: none;
  }
}
