@import '../../../../modules/Styles/resources/variables';
@import '../../../../modules/Styles/resources/theme_variables';
@import '../../../../modules/Styles/resources/typography_variables';
@import '../../../../modules/Styles/resources/mixins';

.theatre-metadata {
  display: flex;
  flex-direction: column;

  .view-transition {
    opacity: 0;
    visibility: hidden;
  }

  &__actions-ctn {
    flex: 0 0 auto;
    position: -webkit-sticky;
    position: sticky;
    top: $appHeaderHeight;
    z-index: 20;
  }

  &__swipe-ctn {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__date-selector {
    width: 100%;
    height: $dateSelectorHeight;
  }

  &__back {
    color: $color-white;
  }

  &__nav {
    padding: $space ($space * 2) ($space / 2) ($space * 2);

    &__switch-ctn {
      display: flex;
      justify-content: center;
      margin-top: ($space * 1.5);
    }
  }

  &__metric-list {
    padding: $space ($space * 2) ($space * 2);
  }

  &__occupancy {
    margin: 0 auto;
    margin-bottom: ($space * 1.5);
  }

  &__view {
    margin: 0 auto;
    flex: 1;
    width: 100%;
    box-sizing: border-box;

    &--films {
      display: flex;
    }

    &--screen {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &--films {
    .theatre-metadata__actions-ctn {
      padding-bottom: $space * 4; // to account for the slanted title list
      background: linear-gradient(-180deg, rgba(#6a32a1, 1) 0%, rgba(#864bba, 1) 100%);
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - #{$diagonal-slant}));

      @media (min-width: 769px) {
        clip-path: none;
      }
    }
  }

  &--screen {
    background: linear-gradient(-180deg, rgba(#6a32a1, 1) 0%, rgba(#b270e0, 1) 100%);
  }
}
