// Font sizes

$base-font-size: 16px;
$type-body: 16px;

// TODO Could switch out for Maps here??

// Input font size
$type-input: $base-font-size;

// Alert font size
$type-alert: $base-font-size;

// Body fontsizes
$type-body-xs: 11px;
$type-body-s: 14px;
$type-body-m: 16px;
$type-body-l: 18px;
$type-body-xl: 22px;
$type-body-xxl: 24px;

// Heading fontsizes
$type-heading-xs: 14px;
$type-heading-s: 16px;
$type-heading-m: 18px;
$type-heading-l: 22px;
$type-heading-xl: 24px;
$type-heading-xxl: 28px;

// Body Line Heights
$lh-type-body-xs: 1.45; // 16 / 11
$lh-type-body-s: 1.7; // 24 / 14
$lh-type-body-m: 1.5; // 24 / 16
$lh-type-body-l: 1.33334; // 24 / 18 Note the number decimal places matter by 1px.
$lh-type-body-xl: 1.45; // 32 / 22
$lh-type-body-xxl: 1.33334; // 32 / 24

// Heading Line Heights
$lh-type-heading-xs: 1.7; // 24 / 14
$lh-type-heading-s: 1.5; // 24 / 16
$lh-type-heading-m: 1.33334; // 24 / 18
$lh-type-heading-l: 1.45; // 32 / 22
$lh-type-heading-xl: 1.33334; // 32 / 24
$lh-type-heading-xxl: 1.4; // 40 / 28

// Font Family
$primary-font: 'Source Sans Pro', sans-serif;

// Font weight

$type-weight-normal: 400;
$type-weight-semibold: 600;

// Font Colours

$primary-font-color: #2a303e; // Change
