@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.title-list {
  width: 100%;
  list-style-type: none;
  margin: 0;
  overflow-y: auto;

  padding-top: $diagonal-gutter;
  margin-top: -($diagonal-gutter);

  @media (min-width: 769px) {
    padding-top: 0;
    margin-top: 0;
  }

  &-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .lazy-load {
      padding: $space * 2;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      pointer-events: none;

      opacity: 0;
      transition: none;

      &--is-visible {
        opacity: 1;
        transition: opacity 300ms linear;
      }
    }

    .list-spinner {
      display: flex;
      flex: 1;
      align-items: center;
    }
  }

  &__empty-ctn {
    color: #2a303e;
    display: flex;
    flex: 1;
    align-items: center;
  }

  &__item {
    background-size: cover;
    background-position: top center;
    margin-top: -($diagonal-gutter);
    padding-bottom: ($space * 3);
    position: relative;
    cursor: pointer;
    clip-path: polygon(0 0, 100% $diagonal-slant, 100% 100%, 0px calc(100% - #{$diagonal-slant}));

    &:last-child:not(:only-child) {
      clip-path: polygon(0 0, 100% $diagonal-slant, 100% 100%, 0 100%);
    }

    &:nth-child(odd) {
      background: theme-var($--theme-title-odd-bg-color);
    }

    &:nth-child(even) {
      background: theme-var($--theme-title-even-bg-color);
    }

    @media (min-width: 769px) {
      &:last-child:not(:only-child) {
        clip-path: none;
      }
      clip-path: none;
      margin-top: 0;
    }

    .item-background {
      &__ctn {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;

        &--placeholder {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &--image {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(#373737, 0.75);
            z-index: 1;
          }
        }
      }

      &__image {
        display: block;
        opacity: 0;
        transition: none;

        width: 100%;
        height: 100%;
        object-fit: cover;

        &--is-loaded {
          opacity: 1;
          transition: opacity 300ms linear;
        }
      }

      &__placeholder {
        height: 54%;
        min-height: calculateRem(130px);
        max-height: calculateRem(155px);
        margin-top: -5%;
        opacity: 0.6;
      }
    }

    .item-content {
      z-index: 2;
      position: relative;
      padding: 0 $space;
      padding-top: $space * 1.5;

      &__inner {
        margin: 0 auto;
        box-sizing: border-box;
        max-width: calculateRem(450px);
        padding: ($space * 0.5) 0 ($space * 2.5) 0;
      }
    }

    .item {
      &__run-week {
        padding: 0 $space;
        max-width: 33%;
      }

      &__title {
        display: flex;
        margin-top: ($space * 0.5);
        padding: 0 $space;

        .title__rank {
          margin-right: $space;
          margin-top: ($space / 2);
        }
      }

      &__metrics {
        max-width: calculateRem(450px);
        margin: 0 auto;
        padding: $space / 2;
        display: flex;
        flex-wrap: wrap;
      }

      &__metric {
        flex: 1 1 33%;
        padding: $space / 2;
        box-sizing: border-box;
        word-break: break-word;

        &--highlighted {
          flex: 1 1 50%;
        }

        &__value {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
  }
}
