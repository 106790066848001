@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.section {
  // `.section`s do not define `flex` so they can't shrink or grow.
  // `.section-container`s grow beyond the size of their contents and shrink to fit available space, hidin overflow.
  &-container {
    @include flex-full-container;
    overflow: hidden;

    &--scrollable {
      overflow: auto;
    }
  }

  &,
  &-button,
  &-container {
    @at-root .section-container--bordered > &:not(:first-child) {
      // All but first child `.section(-container)` of .`section-container--bordered` get a top border.
      border-top: 1px solid #dce7ef;
    }
  }

  // `.section`s & `.section-button`s include a padding, `.section-container`s do not.
  & {
    padding: ($space) ($space * 2);
  }

  &-button {
    padding: ($space * 2);
    margin: 0;
    display: flex;
    flex: none;
    justify-content: space-between;

    > .icon {
      margin-left: ($space / 2);
    }
  }
}
