// Paths

$image-path: '../../images/';

// Slant Variable
$diagonal-slant: 40px; // locking to px rather than percent. As percent gets skewed as the height changes.
$diagonal-gutter: 41px; // extra pixel to account for occasional sub-pixel rendering;

// Colours

$color-white: #ffffff;
$color-light: #f8f8f8;
$color-green: #9acc36;
$color-red: #ff8c8d;
$color-gray: #e6eef4;
$color-dark-gray: #6fa1bb;
$color-orange: #fd7e14;

$text: #2a303e;

$color-danger: $color-red;
$color-success: $color-green;
$color-info: #3c9cd8;
$color-warning: $color-white;

$modal-overlay-bg-color: rgba(#2a303e, 0.4);

// Layout
$app-max-width: 768px; // Maximum width of application container
$app-min-width: 320px;

$baseline: 8px;
$space: 8px; // To keep consistency, only ever multiply by a whole number when using with margins, padding, etc.

// Typography
// * Type styles are found in _type.scss *

// To use: z-index: map-get($z-index, navigation);
$z-index: (
  metric-list-ctn: 10,
  date-selector-ctn: 20,
  back-to-top: 90,
  app-sidebar: 205,
  app-header: 100,
  app-drawer-hidden: 95,
  app-drawer-overlay: 304,
  app-drawer-visible: 305,
  app-body-overlay: 200,
  app-body-element-popover: 200,
  app-notification-banner: 400,
);

$appHeaderHeight: 50px;

/**
  COMPONENT Variables
**/
$dateSelectorHeight: 62px;

// Auth pages
$input-border-color: #b3cedb;
$input-border-color-error: $color-danger;
$input-bg-color-error: rgba($color-danger, 0.2);
/**
  MODULE Variables
**/
$appHeaderHeight: 50px;
