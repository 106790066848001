@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.accordian {
  &__item {
    &-header {
      display: flex;

      &__content {
        flex: 1;
        display: flex;
        align-items: center;
      }

      &__arrow {
        padding: $space;
        padding-right: 0;
        color: theme-var($--theme-accordian-arrow-color);

        .icon {
          font-size: calculateRem(13px);
        }

        &-ctn {
          flex: 0 0 auto;
          display: flex;
          align-items: center;
        }
      }
    }

    &-content {
      height: 0;
      display: none;
      overflow: hidden;
    }
  }
}
