@import '../../../Styles/resources/variables';
@import '../../../Styles/resources/theme_variables';
@import '../../../Styles/resources/typography_variables';
@import '../../../Styles/resources/mixins';

.lists {
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #dde7ef;
    align-items: center;
    padding: $space * 2;
  }

  .selected {
    color: #045c88;
  }
}
