@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.selector-button {
  border: none;
  background-color: transparent;
  cursor: pointer;

  border-radius: 20px;
  padding: $space ($space * 2);

  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  &__arrow {
    width: $space;
    height: $space;
    margin-left: ($space * 2);
  }

  &--light {
    color: theme-var($--theme-selector-button-color--light);
    background: theme-var($--theme-selector-button-bg-color--light);

    .icon {
      color: theme-var($--theme-selector-button-color--light);
    }
  }

  &--dark {
    color: theme-var($--theme-selector-button-color--dark);
    background: theme-var($--theme-selector-button-bg-color--dark);

    .icon {
      color: theme-var($--theme-selector-button-color--dark);
    }
  }
}
