// Typographic styles

body {
  font-family: $primary-font;
  font-weight: $type-weight-normal;
  line-height: 1.5;
  color: $primary-font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

// Heading Text
// SHOULD WE move this all to the typography Folder??
h1,
.heading--xxl {
  @include font-size($type-heading-xxl);
  line-height: $lh-type-heading-xxl;
}

h2,
.heading--xl {
  @include font-size($type-heading-xl);
  line-height: $lh-type-heading-xl;
}

h3,
.heading--l {
  @include font-size($type-heading-l);
  line-height: $lh-type-heading-l;
}

h4,
.heading--m {
  @include font-size($type-heading-m);
  line-height: $lh-type-heading-m;
}

h5,
.heading--s {
  @include font-size($type-heading-s);
  line-height: $lh-type-heading-s;
}

h6,
.heading--xs {
  @include font-size($type-heading-xs);
  line-height: $lh-type-heading-xs;
}

// Transforms
.heading--uppercase {
  text-transform: uppercase;
}

// Body Text

p {
  @include font-size($type-body-m);
  line-height: $lh-type-body-m;
}

.text--xxl {
  @include font-size($type-body-xxl);
  line-height: $lh-type-body-xxl;
}

.text--xl {
  @include font-size($type-body-xl);
  line-height: $lh-type-body-xl;
}

.text--l {
  @include font-size($type-body-l);
  line-height: $lh-type-body-l;
}

.text--m {
  @include font-size($type-body-m);
  line-height: $lh-type-body-m;
}

.text--s {
  @include font-size($type-body-s);
  line-height: $lh-type-body-s;
}

.text--xs {
  @include font-size($type-body-xs);
  line-height: $lh-type-body-xs;
}

.bold {
  font-weight: $type-weight-semibold;
}

.italic {
  font-style: italic;
}
