@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.no-access-wrapper {
  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__image-popcorn {
    width: calculateRem(83px);
  }

  &__title {
    margin-top: ($space * 2);
    margin-bottom: $space;
  }

  &__text {
    width: calculateRem(221px);
  }
}
