@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.element-popover {
  $popover: &;
  $arrow-size: 8px;

  position: relative;

  &__toggle {
    cursor: pointer;
  }

  &__content {
    $content: &;

    position: absolute;
    top: 100%;
    min-width: calculateRem(200px);
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(46, 60, 73, 0.25);
    background-color: $color-white;
    z-index: map-get($z-index, app-body-element-popover);
    margin-top: $arrow-size * 0.5;

    &--attach-left {
      left: 0;
    }

    &--attach-right {
      right: 0;
    }

    &--ported {
      position: fixed;

      &.element-popover__content--attach-right {
        transform: translateX(-100%);
      }
    }

    &--has-arrow {
      margin-top: $arrow-size * 1.5;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        box-sizing: border-box;
        border: $arrow-size solid transparent;
        border-top-color: $color-white;
        border-left-color: $color-white;
        transform: rotate(45deg) translate(-$arrow-size);
        box-shadow: -3px -3px 12px -3px rgba(46, 60, 73, 0.25);
      }

      &#{$content}--attach-left:before {
        left: $space * 1.5;
      }

      &#{$content}--attach-right:before {
        right: $space * 1.5;
      }
    }
  }
}
