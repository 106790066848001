@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.toggle-switch {
  background: theme-var($--theme-toggle-switch-bg);
  border-radius: ($space * 3);
  padding: 4px;

  &__inner {
    position: relative;
  }
  
  &__list {
    display: inline-flex;
    position: relative;
    
    &__item {
      flex: 1;
      white-space: nowrap;
      margin-right: -($space);

      &:last-child {
        margin-right: 0;
      }

      .item__button {
        color:theme-var($--theme-toggle-switch-color);
        text-transform: uppercase;
        padding: ($space / 2) ($space * 2);
      }
    }
  }

  &__bg-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    
    &__indicator {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color:theme-var($--theme-toggle-switch-button-bg-active);
      border-radius: 24px;
      transform: translatez(0);
      transition: left 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms, width 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    }
  }
}



