@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.toggle-button {
  $size-check: 23px;

  width: 49px;
  height: 27px;
  position: relative;
  display: inline-block;

  &--disabled {
    opacity: 0.4;
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .slider {
      background-color: theme-var($--theme-toggle-switch-bg);

      &:before {
        -webkit-transform: translateX($size-check);
        -ms-transform: translateX($size-check);
        transform: translateX($size-check);
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6eef4;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: $size-check;
      width: $size-check;
      left: 0;
      bottom: 1px;
      background-color: $color-white;
      border: 1px solid theme-var($--theme-toggle-switch-bg);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  .slider.round {
    border-radius: 16px;
    &:before {
      border-radius: 50%;
    }
  }

  .default-theme {
    input:checked + .slider {
      background-color: map-get($theme-map-films, $--theme-toggle-switch-color);
    }

    .slider:before {
      border: 1px solid map-get($theme-map-films, $--theme-toggle-switch-bg);
    }
  }
}
