/**
  THEMES

  Using CSS variables in SCSS to theme different color schemes for different pages.

  Idea came from: https://bcodes.io/blog/post/theming-css-variables-in-scss

  We currently have four theme map variables:
  $theme-map-films    - Used on /films route pages
  $theme-map-markets  - Used on /markets route pages
  $theme-map-theatres - Used on /theatres route pages
  $theme-map-default  - Used on /auth route pages & for components with the defaultTheme prop.

  Where $theme-map-default and $theme-map-markets are currently pointing to $theme-map-films.

  When the url path changes, the HTML root element className will get changed in ThemeChanger to either .theme--${theatres || films || default}.
  The below variables are then able to be used to adjust the colors of different modules and components.
    Example of use:
    .spinner {
      border-top-color: theme-var($--theme-spinner-bg-color);
    }

  Some components when used in the AppSidebar or AppHeader for e.g shouldn't have their colours altered by what the current route path is.
  Therefore some components have a defaultTheme prop which can point to the current $theme-map-default.
    Example of use:
    .spinner {
      border-top-color: theme-var($--theme-spinner-bg-color);
      
      &--default-theme {
        border-top-color: map-get($theme-map-default, $--theme-spinner-bg-color);
      }
    }
**/
$--theme-page-text-color: --theme-page-text-color;

// component themes
$--theme-arrow-color: --theme-arrow-color;

$--theme-accordian-arrow-color: --theme-accordian-arrow-color;

$--theme-backtotop-bg-color: --theme-backtotop-bg-color;

$--theme-selector-button-bg-color--light: --theme-selector-button-bg-color--light;
$--theme-selector-button-color--light: --theme-selector-button-color--light;
$--theme-selector-button-bg-color--dark: --theme-selector-button-bg-color--dark;
$--theme-selector-button-color--dark: --theme-selector-button-color--dark;

$--theme-button-primary-bg: --theme-button-primary-bg;
$--theme-button-secondary-bg: --theme-button-secondary-bg;
$--theme-button-secondary-color: --theme-button-secondary-color;
$--theme-button-primary-color: --theme-button-primary-color;

$--theme-radio-border-color: --theme-radio-border-color;
$--theme-radio-border-color--checked: --theme-radio-border-color--checked;
$--theme-radio-bg-color: --theme-radio-bg-color;
$--theme-radio-bg-color--checked: --theme-radio-bg-color--checked;

$--theme-toggle-switch-bg: ----theme-toggle-switch-bg;
$--theme-toggle-switch-color: --theme-toggle-switch-color;

$--theme-drawer-contents-border-color: --theme-drawer-contents-border-color;
$--theme-drawer-marker-bg-color: --theme-drawer-marker-bg-color;

$--theme-spinner-bg-color: --theme-list-spinner-bg-color;
$--theme-spinner-spin-bg-color: --theme-list-spinner-spin-bg-color;

$--theme-sort-toggle-selected: --theme-sort-toggle-selected;
$--theme-sort-toggle-unselected: --theme-sort-toggle-unselected;

// modules
$--theme-theatre-list-item-bg: --theme-theatre-list-item-bg;
$--theme-theatre-list-item-color: --theme-theatre-list-item-color;
$--theme-theatre-list-arrow-color: --theme-theatre-list-arrow-color;
$--theme-theatre-list-item-table-th-bg: --theme-theatre-list-item-table-th-bg;
$--theme-theatre-list-item-table-th-border: --theme-theatre-list-item-table-th-border;

$--theme-toggle-switch-button-bg-active: --theme-toggle-switch-button-bg-active;

$--theme-date-selector-border-color: --theme-date-selector-border-color;
$--theme-date-selector-color-inactive: --theme-date-selector-color-inactive;
$--theme-date-selector-color-active: --theme-date-selector-color-active;

$--theme-share-drawer-button-bg--active: --theme-share-drawer-button-bg--active;

$--theme-range-slider-rail-bg: --theme-range-slider-rail-bg;
$--theme-range-slider-track-bg: --theme-range-slider-track-bg;
$--theme-range-slider-handle-fill-color: --theme-range-slider-handle-fill-color;
$--theme-range-slider-handle-stroke-color: --theme-range-slider-handle-stroke-color;
$--theme-range-slider-handle-label-color: --theme-range-slider-handle-label-color;

$--theme-metric-list-scroll-indicator-color--inactive: --theme-metric-list-scroll-indicator-color--inactive;
$--theme-metric-list-scroll-indicator-color--active: --theme-metric-list-scroll-indicator-color--active;

$--theme-title-odd-bg-color: --theme-title-odd-bg-color;
$--theme-title-even-bg-color: --theme-title-even-bg-color;

// Still TODO - Map all the common colors below to variables.

// Film Analysis Theme
$theme-map-films: (
  $--theme-page-text-color: $color-white,
  // components
    $--theme-arrow-color: #045c88,
  $--theme-accordian-arrow-color: #fd7e14,
  $--theme-backtotop-bg-color: #6fa1bb,
  $--theme-selector-button-bg-color--light: #dce7ef,
  $--theme-selector-button-color--light: #045c88,
  $--theme-selector-button-bg-color--dark: #045c88,
  $--theme-selector-button-color--dark: $color-white,
  $--theme-toggle-switch-bg: #b3cedb,
  $--theme-toggle-switch-color: #045c88,
  $--theme-toggle-switch-button-bg-active: $color-white,
  $--theme-button-primary-bg: #045c88,
  $--theme-button-primary-color: $color-white,
  $--theme-button-secondary-bg: #dce7ef,
  $--theme-button-secondary-color: #2a303e,
  $--theme-radio-border-color: #045c88,
  $--theme-radio-border-color--checked: #045c88,
  $--theme-radio-bg-color: $color-white,
  $--theme-radio-bg-color--checked: #ebf2f6,
  $--theme-drawer-contents-border-color: #dce7ef,
  $--theme-drawer-marker-bg-color: #e6eef4,
  $--theme-spinner-bg-color: #6fa1bb,
  $--theme-spinner-spin-bg-color: #dce7ef,
  $--theme-sort-toggle-selected: #045c88,
  $--theme-sort-toggle-unselected: #6fa1bb,
  // modules
    $--theme-theatre-list-item-bg: $color-white,
  $--theme-theatre-list-item-color: #2a303e,
  $--theme-theatre-list-arrow-color: #034b6f,
  $--theme-theatre-list-item-table-th-bg: #ebf2f6,
  $--theme-theatre-list-item-table-th-border: #dce7ef,
  $--theme-date-selector-border-color: #045c88,
  $--theme-date-selector-color-inactive: #b3cedb,
  $--theme-date-selector-color-active: $color-white,
  $--theme-share-drawer-button-bg--active: #dce7ef,
  $--theme-range-slider-rail-bg: #dce7ef,
  $--theme-range-slider-track-bg: #045c88,
  $--theme-range-slider-handle-fill-color: #e6eef4,
  $--theme-range-slider-handle-stroke-color: #045c88,
  $--theme-range-slider-handle-label-color: #045c88,
  $--theme-metric-list-scroll-indicator-color--active: #6fa1bb,
  $--theme-metric-list-scroll-indicator-color--inactive: #034b6f,
  $--theme-title-odd-bg-color: #045c88,
  $--theme-title-even-bg-color: #09749d,
);

// Theatre Analysis Theme
$theme-map-theatres: (
  $--theme-page-text-color: $color-white,
  // components
    $--theme-arrow-color: #62279d,
  $--theme-accordian-arrow-color: #fd7e14,
  $--theme-backtotop-bg-color: #b59bd3,
  $--theme-selector-button-bg-color--light: #dbcfea,
  $--theme-selector-button-color--light: #62279d,
  $--theme-selector-button-bg-color--dark: #7b47af,
  $--theme-selector-button-color--dark: $color-white,
  $--theme-toggle-switch-bg: #b59bd3,
  $--theme-toggle-switch-color: #62279d,
  $--theme-toggle-switch-button-bg-active: $color-white,
  $--theme-button-primary-bg: #7b47af,
  $--theme-button-primary-color: $color-white,
  $--theme-button-secondary-bg: #dbcfea,
  $--theme-button-secondary-color: #62279d,
  $--theme-radio-border-color: #62279d,
  $--theme-radio-border-color--checked: #62279d,
  $--theme-radio-bg-color: $color-white,
  $--theme-radio-bg-color--checked: #dbcfea,
  $--theme-drawer-contents-border-color: #edebf6,
  $--theme-drawer-marker-bg-color: #edebf6,
  $--theme-spinner-bg-color: #b59bd3,
  $--theme-spinner-spin-bg-color: #edebf6,
  $--theme-sort-toggle-selected: #62279d,
  $--theme-sort-toggle-unselected: #b59bd3,
  // modules
    $--theme-theatre-list-item-bg: #f1ecf7,
  $--theme-theatre-list-item-color: #2a303e,
  $--theme-theatre-list-arrow-color: #62279d,
  $--theme-theatre-list-item-table-th-bg: #dbcfea,
  $--theme-theatre-list-item-table-th-border: #edebf6,
  $--theme-date-selector-border-color: #62279d,
  $--theme-date-selector-color-inactive: #b59bd3,
  $--theme-date-selector-color-active: $color-white,
  $--theme-share-drawer-button-bg--active: #edebf6,
  $--theme-range-slider-rail-bg: #f1ecf7,
  $--theme-range-slider-track-bg: #7b47af,
  $--theme-range-slider-handle-fill-color: #f1ecf7,
  $--theme-range-slider-handle-stroke-color: #7b47af,
  $--theme-range-slider-handle-label-color: #7b47af,
  $--theme-metric-list-scroll-indicator-color--active: #b59bd3,
  $--theme-metric-list-scroll-indicator-color--inactive: #62279d,
  $--theme-title-odd-bg-color: #6a32a1,
  $--theme-title-even-bg-color: #8e51c1,
);

$theme-map-markets: (
  $--theme-page-text-color: $color-white,
  // components
    $--theme-arrow-color: #045c88,
  $--theme-accordian-arrow-color: #fd7e14,
  $--theme-backtotop-bg-color: #6fa1bb,
  $--theme-selector-button-bg-color--light: #dce7ef,
  $--theme-selector-button-color--light: #045c88,
  $--theme-selector-button-bg-color--dark: #045c88,
  $--theme-selector-button-color--dark: $color-white,
  $--theme-toggle-switch-bg: #b3cedb,
  $--theme-toggle-switch-color: #045c88,
  $--theme-toggle-switch-button-bg-active: $color-white,
  $--theme-button-primary-bg: #045c88,
  $--theme-button-primary-color: $color-white,
  $--theme-button-secondary-bg: #dce7ef,
  $--theme-button-secondary-color: #2a303e,
  $--theme-radio-border-color: #045c88,
  $--theme-radio-border-color--checked: #045c88,
  $--theme-radio-bg-color: $color-white,
  $--theme-radio-bg-color--checked: #ebf2f6,
  $--theme-drawer-contents-border-color: #dce7ef,
  $--theme-drawer-marker-bg-color: #e6eef4,
  $--theme-spinner-bg-color: #6fa1bb,
  $--theme-spinner-spin-bg-color: #dce7ef,
  $--theme-sort-toggle-selected: #045c88,
  $--theme-sort-toggle-unselected: #6fa1bb,
  // modules
    $--theme-theatre-list-item-bg: $color-white,
  $--theme-theatre-list-item-color: #2a303e,
  $--theme-theatre-list-arrow-color: #034b6f,
  $--theme-theatre-list-item-table-th-bg: #ebf2f6,
  $--theme-theatre-list-item-table-th-border: #dce7ef,
  $--theme-date-selector-border-color: #045c88,
  $--theme-date-selector-color-inactive: #b3cedb,
  $--theme-date-selector-color-active: $color-white,
  $--theme-share-drawer-button-bg--active: #dce7ef,
  $--theme-range-slider-rail-bg: #dce7ef,
  $--theme-range-slider-track-bg: #045c88,
  $--theme-range-slider-handle-fill-color: #e6eef4,
  $--theme-range-slider-handle-stroke-color: #045c88,
  $--theme-range-slider-handle-label-color: #045c88,
  $--theme-metric-list-scroll-indicator-color--active: #6fa1bb,
  $--theme-metric-list-scroll-indicator-color--inactive: #034b6f,
  $--theme-title-odd-bg-color: #1d2d42,
  $--theme-title-even-bg-color: #32435c,
);

$theme-map-default: $theme-map-films;
