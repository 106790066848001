@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.drawer {
  $border-radius: $space * 2;
  $header-height: 60px;

  position: fixed;
  z-index: map-get($z-index, app-drawer-hidden);
  color: $text;
  bottom: 0; //TODO make dynamic
  left: 0;
  right: 0;
  top: 100%;
  margin: auto auto;
  max-width: $app-max-width;

  &--visible {
    z-index: map-get($z-index, app-drawer-visible);
  }

  &-overlay {
    z-index: map-get($z-index, app-drawer-overlay);
    background-color: $modal-overlay-bg-color;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  &__header {
    text-align: center;
    padding: $space;
    border-bottom: solid 1px theme-var($--theme-drawer-contents-border-color);
    max-height: $header-height;
    overflow: hidden;
    right: 0;
    left: 0;
    background-color: $color-white;
    border-radius: $border-radius $border-radius 0 0;

    &__children {
      padding: $space;
    }

    &--visible-on-screen {
      position: absolute;
      top: -$header-height;
    }
  }

  &__content {
    background-color: $color-white;
    max-height: 60vh;
    overflow: auto;
  }

  &__section {
    padding: $space ($space * 2);
  }

  &__divider {
    border-top: solid 1px theme-var($--theme-drawer-contents-border-color);
    border-bottom: none;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__footer {
    background-color: $color-white;
    border-top: solid 1px theme-var($--theme-drawer-contents-border-color);
    padding: ($space * 2) ($space * 3);

    &--align-center {
      text-align: center;
    }

    &--align-right {
      text-align: right;

      .button:last-child {
        margin-right: 0;
      }
    }

    &--align-left {
      text-align: left;

      .button:last-child {
        margin-left: 0;
      }
    }
  }

  &__marker {
    background-color: theme-var($--theme-drawer-marker-bg-color);
    border-radius: 2px;
    height: $space / 2;
    width: $space * 6;
    margin: 0 auto;
  }

  &--default-theme {
    .drawer {
      &__marker {
        background-color: map-get($theme-map-default, $--theme-drawer-marker-bg-color);
      }

      &__footer,
      &__divider,
      &__header {
        border-top-color: map-get($theme-map-default, $--theme-drawer-contents-border-color);
      }
    }
  }
}
