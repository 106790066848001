@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.territory-select {
  &__search {
    flex: 0 0 auto;
    margin-top: $space;
    margin-bottom: ($space * 2);
    margin-right: $space;

    img {
      width: 100%;
      display: block;
    }
  }

  &__scroll {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    flex: 1;

    &__inner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__divider {
    border: none;
    border-bottom: 1px solid #dce7ef;
    margin: 0;
  }

  &__no-result {
    text-align: center;
  }

  .territory-list {
    margin: $space 0;

    &__title {
      text-transform: uppercase;
      color: #fd7e14;
      margin-bottom: $space;
    }

    .loading--spinner {
      padding: 8px;
    }

    .list__item {
      display: flex;

      .btn-territory {
        flex: 1;
        text-align: left;
        padding-left: 0;

        &--is-active {
          color: #fd7e14;
        }
        &--is-disabled {
          cursor: default;
        }
      }

      .btn-favourite {
        flex: 0 0 auto;

        .icon {
          color: #6fa1bb;
        }

        &--is-active {
          .icon {
            color: #fd7e14;
          }
        }
      }
    }
  }
}
