@import '../Styles/resources/variables';
@import '../Styles/resources/theme_variables';
@import '../Styles/resources/typography_variables';
@import '../Styles/resources/mixins';

.comps-titles {
  &__heading {
    color: theme-var($--theme-selector-button-color--light);
  }

  &__section-heading {
    color: $color-orange;
  }

  &__no-market-text {
    color: $color-red;
  }

  &__accordian {
    padding: $space 0;

    &-item {
      padding: 0 ($space * 2);

      &-header {
        padding: ($space * 1.5) 0 $space 0;
      }

      &-content {
        padding-bottom: ($space * 2);
      }

      .loading--spinner {
        margin: $space * 2 auto;
      }
    }
  }

  &__empty-text {
    color: $color-dark-gray;
  }

  &__search-results {
    min-height: 115px;

    &__placeholder {
      max-width: 280px;
      margin: $space * 2 auto;
      text-align: center;

      .icon {
        font-size: calculateRem(58px);
        margin: 0 auto;
        margin-bottom: $space;
        color: #e6eef4;
      }
    }

    .loading--spinner {
      margin: $space * 2 auto;
    }
  }
}
