@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.sort-toggle {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  cursor: pointer;

  &--toggle-pos--left {
    flex-direction: row-reverse;
  }

  &--toggle-pos--right {
    flex-direction: row;
  }

  &__icon {
    position: relative;
    display: inline-block;
    line-height: calculateRem(12px);
    height: calculateRem(12px);
    width: calculateRem(12px);

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 0;
      display: block;
      height: 0;
      border: calculateRem(4px) solid transparent;
      border-radius: 2px;
    }

    &:before {
      border-bottom-color: var(--theme-sort-toggle-unselected);
      margin-top: calculateRem(-9px);
    }

    &:after {
      border-top-color: var(--theme-sort-toggle-unselected);
      margin-top: calculateRem(1px);
    }

    &--asc {
      &:before {
        border-bottom-color: var(--theme-sort-toggle-selected);
      }
    }

    &--desc {
      &:after {
        border-top-color: var(--theme-sort-toggle-selected);
      }
    }
  }
}
