@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.horizontal-swipe-buttons {
  $action-width: 63px; // JSX dependency

  &__main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // height: same as content child by JQuery.

    &--mb {
      margin-bottom: $space;
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    pointer-events: auto;
    transform-origin: 50% 50% 0;
    border-radius: 4px;
  }

  &__content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    z-index: 1;
  }

  &__content:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  &__actions {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    z-index: 0;

    .button {
      margin: 0;
      display: flex;
      flex: 0 0 $action-width;
      justify-content: center;
      align-items: center;
    }
  }
}
