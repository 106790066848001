@import '../../modules/Styles/resources/variables';
@import '../../modules/Styles/resources/theme_variables';
@import '../../modules/Styles/resources/typography_variables';
@import '../../modules/Styles/resources/mixins';

.alert {
  @include font-size($type-alert);
  display: flex;
  padding: ($space * 2);

  &__message {
    flex: 1;
    margin-left: ($space * 2);
  }

  &__icon {
    margin-top: calculateRem(2px);
    font-size: calculateRem(20px);
  }

  &--error {
    background-color: $color-danger;
    color: $color-white;
  }

  &--success {
    background-color: $color-success;
    color: $color-white;
  }

  &--info {
    background-color: $color-info;
    color: $color-white;
  }

  &--inline {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    transition: 0.4s ease-in-out;
    transform: translateY(0);
    border-bottom: 1px solid $color-white;

    .alert {
      &__message {
        flex: initial;
      }
    }

    &.alert--hidden {
      transform: translateY(-100%);
    }
  }
}
